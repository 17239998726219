import React from 'react';
import {routerRedux, Route, Switch} from 'dva/router';
import {LocaleProvider} from 'antd';

const {ConnectedRouter} = routerRedux;
import zhCN from 'antd/lib/locale-provider/zh_CN';
import {getConfig} from './siteConfig';

const AppComponent = getConfig('AppComponent');

export default ({history, app}) => {
  return (
    <LocaleProvider locale={zhCN}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={AppComponent}/>
        </Switch>
      </ConnectedRouter>
    </LocaleProvider>
  );
}
