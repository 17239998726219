import React from 'react';
import { Carousel, WingBlank } from 'antd-mobile';
import './carousel.less';
import config from 'src/framework/common/config';
const {fileDomain} = config;

class FocusFigure extends React.Component {
  state = {
    imgHeight: 220,
  };
  componentDidMount() {}
  render() {
    const { data, results } = this.props;
    let imgUrl = data.indexOf(',') > -1 ? data.split(',')[0]:data;
    return (
      <div>
        {results.flickCount > 4 ? (
          <div className="carouselWrap Five">
            <img
              src={fileDomain+'/'+imgUrl}
              alt=""
              style={{ width: '100%', verticalAlign: 'top', height: 220 }}
              onLoad={() => {
                window.dispatchEvent(new Event('resize'));
                this.setState({ imgHeight: '220px' });
              }}
            />
          </div>
        ) : results.flickCount > 2 ? (
          <div className="carouselWrap Three">
            <img
              src={fileDomain+'/'+imgUrl}
              alt=""
              style={{ width: '100%', verticalAlign: 'top', height: 220 }}
              onLoad={() => {
                window.dispatchEvent(new Event('resize'));
                this.setState({ imgHeight: '220px' });
              }}
            />
          </div>
        ) : (
          <div className="carouselWrap One">
            <img
              src={fileDomain+'/'+imgUrl}
              alt=""
              style={{ width: '100%', verticalAlign: 'top', height: 220 }}
              onLoad={() => {
                window.dispatchEvent(new Event('resize'));
                this.setState({ imgHeight: '220px' });
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default FocusFigure;
