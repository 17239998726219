import React from 'react';
import './carousel.less';
import moment from 'moment';

class BrandInfor extends React.Component {
  state = {
    data: [],
  };

  componentDidMount() {}

  render() {
    const { data, fakeCode, } = this.props;
    const createDate = moment(data.first_flick_time).format('YYYY-MM-DD HH:mm:ss');
    return (
      <div className="brandWrap">
        <div className="brand-warp-content">
          <div className="brand-title">身份码信息</div>
          <span className="brand-mooncakes">{data.productName}</span>
          {data.blockNo ? (
            <div>
              {' '}
              <div className="brand-bum-bg">
                <div className="brand-number">
                  <span>区块链全球唯一编号</span>
                  <br />
                  {data.blockNo}
                </div>
              </div>
              <hr width="80%" />
              <div>
                <span>区块链Hash</span>
                <br />
                {data.blockHash}
              </div>
              <hr width="80%" />
            </div>
          ) : null}
          <div>
            <span className="blue">产品身份证</span>
            <br />
            {fakeCode}
          </div>
          <hr width="80%" />
          <div>
            <span>第一次查询时间戳</span>
            <br />
            {createDate}
          </div>
        </div>
      </div>
    );
  }
}

export default BrandInfor;
