import antdMessage from 'antd/es/message';
import antdLocaleProvider from 'antd/es/locale-provider';

const href = '/styles/antd.min.css';
const link = document.createElement('link');
link.setAttribute('rel', 'stylesheet');
link.setAttribute('type', 'text/css');
link.setAttribute('href', href);
document.head.appendChild(link);
//
export const message = antdMessage;
export const LocaleProvider = antdLocaleProvider;
