import React from 'react';
import './index.less';
import { connect } from 'dva';

@connect()
class ErrorCode extends React.Component {
  state = {
    results: '',
  };

  componentDidMount() {
    const results = this.props.data;
    this.setState({ results });
  }

  render() {
    const { results } = this.state;
    return (
      <div className="errorCode-warp">
        {results.tips === '您所查询的标签有误' || results.tips === '该码不存在，请仔细核对！' ? (
          <div className="errorCode" />
        ) : (
          <div className="errorTime" />
        )}
        <div className="errorText">{results.tips}</div>
        <footer>
          <a href="http://www.app315.net/" target="_blank">
            技术支持： 甲骨文超级码科技股份 <br />
            Copyright 2005 - 2020 All Reserved
          </a>
        </footer>
      </div>
    );
  }
}

export default ErrorCode;
