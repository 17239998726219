import React from 'react';
import "./carousel.less";
import { routerRedux, Switch, Route } from 'dva/router';
import Zmage from 'react-zmage';
import { connect } from 'dva';
import { ajaxSync } from 'src/framework/utils/ajax';
import api from 'src/sites/antiFakeH5_2/config/api';
import config from 'src/framework/common/config';
import icon from '../assets/icon.png';
const {fileDomain} = config;
import noData from '../assets/no-data.png';

@connect()
class AntiFake extends React.Component {
  state = {
    data:[],
  };
  componentDidMount() {}

  handleClick = async () => {
    location.reload()
    let apiUrl = null;
    let res = {};
    const params = this.props.params;
    apiUrl = api.homePage.SET_REFRESH;
    res = await ajaxSync.get(apiUrl, params);
    if (res.res.state === 200) {
      const _data = res.res.results;
      sessionStorage.clear();
      sessionStorage.globalFake = JSON.stringify({
        globalFakeSearchId: _data.globalFakeSearchId,
        codeTypeId: params.codeTypeId,
        fakeCode: params.fakeCode,
      });
      if (
        _data.tips === '您所查询的标签暂未生效' ||
        _data.tips === '您所查询的标签有误' ||
        _data.tips === '服务器异常'
      ) {
        this.setState({
          sdata: _data,
        });
        this.props.dispatch(routerRedux.push({ pathname: '/errorcode', payload: { _data } }));
      } else {
        this.setState({
          sdata: _data,
        });
        this.props.dispatch(
          routerRedux.push({ pathname: '/antiFake', payload: { _data, params } })
        );
      }
    }
  };

  render() {
    const { data } = this.props;
    let imageUrl = '';
    data.whetherSetOpenPiData && data.whetherOpenPi
      ? (imageUrl = data.piUrl)
      : data.fakeUrl? (imageUrl = data.fakeUrl) : null;
    return (
      <div className="antiWrap">
       <div className='anti-warp-content'>
       {!data.piUrl && data.whetherOpenPi ? (
            <div className ='imageWarp'>
              <div onClick={this.handleClick} className ='imageRefresh'>
                <span>图片合成失败请点击刷新</span>
                <img src={icon} />
              </div>
              <img src={`${fileDomain}/${imageUrl}`} />
            </div>
          ) : (
            <span style={!imageUrl?{width:'200px',display:'inline-block'}:{width:'100%'}}>
              <Zmage src={imageUrl ? `${fileDomain}/${imageUrl}`: noData} />
            </span>
          )}
          <div style={!imageUrl?{color:'#323232'}:{color:'#A0A0A0'}}>
            {imageUrl || data.whetherOpenPi ?'查询小贴士：产品所贴标识应与上图一致,请核对序号①②③④⑤⑥所标注区域信息是否与防伪标示一致，如有差异，请联系厂商并谨防假冒':'暂无防伪标识图片'}
          </div>
       </div>
       <div className='antiWrap-button'>请仔细核对标签上防伪技术</div>
      </div>
    );
  }
}

export default AntiFake;
