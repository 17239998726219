import './polyfill';
import dva from 'dva';
import createLoading from 'dva-loading';
import 'moment/locale/zh-cn';
import './rollbar';

const createHistory = require("history").createHashHistory;


export default () => {
  const app = dva({history: createHistory({hashType: 'slash'})});
  app.use(createLoading());
  //
  app.router(require('./router').default);
  app.start('#root');
  return app;
}
//export default app._store; // eslint-disable-line
