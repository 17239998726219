import React from 'react';
import { Modal, Steps } from 'antd-mobile';
import config from 'src/framework/common/config';
import './carousel.less';
import icon from '../assets/icon.png';
import { routerRedux, Switch, Route } from 'dva/router';
import { connect } from 'dva';
import { ajaxSync } from 'src/framework/utils/ajax';
import api from 'src/sites/antiFakeH5_2/config/api';
import noData from '../assets/no-data.png';

const Step = Steps.Step;

const { fileDomain } = config;
@connect()
export default class extends React.Component {
  state = {
    isPlay: 1,
  };

  isplay() {
    const { isPlay } = this.state;
    if (isPlay === 1) {
      this.refs.audio.play();
      this.setState({ isPlay: 2 });
    } else {
      this.refs.audio.pause();
      this.setState({ isPlay: 1 });
    }
  }

  firstAnti() {
    const { voiceUrlAdress, voiceContent } = this.props;
    return (
      <div className="modal-content-voice">
        <span onClick={this.isplay.bind(this)}>
          语音播放
          <audio src={`${fileDomain}/${voiceUrlAdress}`} ref="audio" />
        </span>
        &nbsp;&nbsp;&nbsp;
        {voiceContent.prompt}
      </div>
    );
  }

  firstSearch() {
    return (
      <div>
        <div className="modal-title-search">
          <div>正</div>
        </div>
        <div className="modal-content-text">该品牌是第1次防伪验证</div>
      </div>
    );
  }

  resultNormal() {
    const { voiceContent, cont, tip } = this.props;
    let msgTip = null;
    if (cont == 4 || cont == 5) {
      msgTip = tip;
    } else {
      msgTip = voiceContent.tip;
    }
    return (
      <div className="modal-content-wrap">
        <div className="modal-result-normal">
          <span>温馨提示：</span>
          {msgTip}
        </div>
      </div>
    );
  }

  resultUnNormal() {
    return (
      <div className="modal-result-normal">
        <div>该标识有疑问，为了保障您的合法权益，我们建议您及时和厂家联系</div>
        <div className="modal-button" onClick={this.props.closeButton.bind(this)}>
          确定
        </div>
      </div>
    );
  }

  // 查询记录
  stepDescription(item, index) {
    return (
      <div>
        <div className="record-container">
          <div className="left">查询时间戳：</div>
          <div className="right">{item.createDate}</div>
        </div>
        <div className="record-container">
          <div className="left">防伪编号：</div>
          <div className="right">{item.fakeCode}</div>
        </div>
        {/* {item.ipAddress && (
          <div className="record-container">
            <div className="left">IP地址：</div>
            <div className="right">{item.ipAddress}</div>
          </div>
        )} */}
        {item.locateAdress && (
          <div className="record-container">
            <div className="left">地理戳：</div>
            <div className="right">{item.locateAdress}</div>
          </div>
        )}
        {item.jingDu && (
          <div className="record-container">
            <div className="left">经度：</div>
            <div className="right">{item.jingDu}</div>
          </div>
        )}
        {item.weiDu && (
          <div className="record-container">
            <div className="left">纬度：</div>
            <div className="right">{item.weiDu}</div>
          </div>
        )}
      </div>
    );
  }

  checkRecord() {
    const { checkrecord } = this.props;
    return (
      <div className="modal-content-checked">
        <Steps current={1}>
          {checkrecord.map((item, index) => {
            return (
              <Step
                key={index}
                icon={
                  index === 0 ? (
                    <div className="icon-checked" />
                  ) : (
                    <div className="icon-unChecked" />
                  )
                }
                description={this.stepDescription(item, index)}
              />
            );
          })}
        </Steps>
        <div className="checked-footer">
          温馨提示：系统根据数据自动判断，如果是亲戚朋友的扫码行为，忽略系统提示
        </div>
      </div>
    );
  }
  handleClick = async () => {
    location.reload()
    let apiUrl = null;
    let res = {};
    const params = this.props.params;
    apiUrl = api.homePage.SET_REFRESH;
    res = await ajaxSync.get(apiUrl, params);
    if (res.res.state === 200) {
      const _data = res.res.results;
      sessionStorage.clear();
      sessionStorage.globalFake = JSON.stringify({
        globalFakeSearchId: _data.globalFakeSearchId,
        codeTypeId: params.codeTypeId,
        fakeCode: params.fakeCode,
      });
      if (
        _data.tips === '您所查询的标签暂未生效' ||
        _data.tips === '您所查询的标签有误' ||
        _data.tips === '服务器异常'
      ) {
        this.setState({
          sdata: _data,
        });
        this.props.dispatch(routerRedux.push({ pathname: '/errorcode', payload: { _data } }));
      } else {
        this.setState({
          sdata: _data,
        });
        this.props.dispatch(
          routerRedux.push({ pathname: '/antiFake', payload: { _data, params } })
        );
      }
    }
  };

  moreTimes() {
    const { voiceContent } = this.props;
    let imageUrl = '';
    voiceContent.whetherSetOpenPiData && voiceContent.whetherOpenPi
    ? (imageUrl = voiceContent.piUrl)
    : voiceContent.fakeUrl ? (imageUrl = voiceContent.fakeUrl) : null ;
    return (
      <div className="modal-more-times">
        <div className="modal-more-time">
          该标识的扫码次数过多，为了识别防伪标识的真假，请检查标识是否与图片一致
        </div>
        <div>
        {!voiceContent.piUrl && voiceContent.whetherOpenPi ? (
            <div className = 'imageWarpModal'>
              <div onClick={this.handleClick} className = 'imageRefreshModal'>
                <span>图片合成失败请点击刷新</span>
                <img src={icon}/>
              </div>
              <img src={`${fileDomain}/${imageUrl}`} />
            </div>
          ) : (
            <span>
              <img src={ imageUrl ?`${fileDomain}/${imageUrl}`:noData} />
            </span>
          )}
        </div>
        <div className="modal-button" onClick={this.props.closeButton.bind(this)}>
          防伪标识和图片一致
        </div>
        <div className="modal-button inconformity" onClick={this.props.unNormal.bind(this)}>
          防伪标识和图片不一致
        </div>
      </div>
    );
  }

  render() {
    const { modal, cont ,voiceUrlAdress} = this.props;
    return (
      <Modal
        visible={modal}
        transparent
        maskClosable={false}
        closable={false}
        title={
          <div className="modal-header">
            {cont === '4'
              ? '温馨提示'
              : cont === 'more'
                ? '查询记录'
                : cont === '1'
                  ? this.firstSearch()
                  : '*请仔细核对标识上的防伪技术'}
            <span onClick={this.props.closeButton.bind(this)}>×</span>
          </div>
        }
        footer={[]}
        className="home-modal-warp"
      >
        {cont === '5'
          ? this.moreTimes()
          : cont === '407'
            ? this.resultUnNormal()
            : cont === '4'
              ? this.resultNormal()
              : cont === '2'
                ? this.resultNormal()
                : cont === '1'
                  ? this.firstAnti()
                  : cont === 'more'
                    ? this.checkRecord()
                    : null}
      </Modal>
    );
  }
}
