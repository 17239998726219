import React from 'react';
import { connect } from 'dva';
import { routerRedux, Switch, Route } from 'dva/router';
import { ajaxSync } from 'src/framework/utils/ajax';
import api from 'src/sites/antiFakeH5_2/config/api';
import { getParams } from 'src/framework/utils/url';
import * as storage from 'src/framework/utils/storage';
import AntiFake from './antiFake';
import ErrorCode from './errorCode';
import './index.less';

//
@connect()
export default class extends React.Component {
  state = {
    data: null,
    params: {},
  };

  componentWillMount() {
    this.postDate();
  }

  componentDidMount() {
    this.getDataTest();
  }

  postDate() {
    const _fackCode = getParams();
    const _sysVersion = this.getOSVersion();
    const _searchEntrance = this.IsWeixinOrAlipay();
    const b_version = navigator.appVersion;
    const _browserVersion = parseFloat(b_version);
    let globalFakeSearchId = null;
    if (sessionStorage.globalFake) {
      const globalFake = JSON.parse(sessionStorage.globalFake);
      if (
        _fackCode.fakecode == globalFake.fakeCode &&
        _fackCode.codetypeid == globalFake.codeTypeId
      ) {
        globalFakeSearchId = globalFake.globalFakeSearchId;
      }
    }
    const data = {
      fakeCode: _fackCode.fakecode,
      codeTypeId: _fackCode.codetypeid,
      browserVersion: _browserVersion,
      sBatchId:_fackCode.sbatchid,
      sysVersion: _sysVersion,
      searchEntrance: _searchEntrance,
      jingdu: '',
      weidu: '',
      isLoation: '',
      globalFakeSearchId,
    };
    this.setState({ params: data });
  }

  getDataTest = async () => {
    const { params } = this.state;
    let apiUrl = null;
    let res = {};
    if (!params.globalFakeSearchId) {
      apiUrl = api.homePage.GET_DETAILS;
      res = await ajaxSync.post(apiUrl, params);
    } else {
      apiUrl = api.homePage.SET_REFRESH;
      res = await ajaxSync.get(apiUrl, params);
    }

    if (res.res.state === 200) {
      const _data = res.res.results;
      this.setState({
        data: _data,
      });
      sessionStorage.clear();
      sessionStorage.globalFake = JSON.stringify({
        globalFakeSearchId: _data.globalFakeSearchId,
        codeTypeId: params.codeTypeId,
        fakeCode: params.fakeCode,
      });
      if (
        _data.tips === '您所查询的标签暂未生效' ||
        _data.tips === '您所查询的标签有误' ||
        _data.tips === '服务器异常' ||
        _data.tips === '该码不存在，请仔细核对！'
      ) {
        this.props.dispatch(routerRedux.push({ pathname: '/errorcode', payload: { _data } }));
      } else {
        this.props.dispatch(
          routerRedux.push({ pathname: '/antiFake', payload: { _data, params } })
        );
      }
    }
  };

  IsWeixinOrAlipay() {
    const ua = window.navigator.userAgent.toLowerCase();
    // 判断是不是微信
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      return '微信';
    }
    // 判断是不是支付宝
    if (ua.match(/AlipayClient/i) == 'alipayclient') {
      return '支付宝';
    }
    // 判断是不是qq
    if (ua.match(/QQ/i) == 'qq') {
      return 'qq';
    }
    if (ua.match(/DingTalk/i) == 'dingtalk') {
      return '钉钉';
    }
    if (ua.match(/UcBrowser/i) == 'ucbrowser') {
      return 'UC浏览器';
    }
    if (navigator.userAgent.indexOf('Chrome') != -1) {
      const is360 = _mime('type', 'application/vnd.chromium.remoting-viewer');
      function _mime(option, value) {
        const mimeTypes = navigator.mimeTypes;
        for (const mt in mimeTypes) {
          if (mimeTypes[mt][option] == value) {
            return true;
          }
        }
        return false;
      }
      if (is360) {
        return '360浏览器';
      } else {
        return '谷歌浏览器';
      }
    }
    if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari浏览器';
    }
    if (navigator.userAgent.indexOf('Firefox') != -1) {
      return '火狐浏览器';
    }
    // 哪个都不是
    return null;
  }

  // 获取操作系统版本
  getOSVersion() {
    let OSVision = '1.0';
    const u = navigator.userAgent;
    const ua = window.navigator.userAgent.toLowerCase();
    const isAndroid = u.indexOf('android') > -1 || u.indexOf('Linux') > -1; // Android
    const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
    if (ua.match(/UcBrowser/i) == 'ucbrowser') {
      if (isIOS) {
        OSVision = navigator.userAgent.split(';')[1].match(/(\d+)_(\d+)_?(\d+)?/)[0];
      } else {
        OSVision = navigator.userAgent.split(';')[2];
      }
      return OSVision;
    }
    if (isAndroid) {
      OSVision = navigator.userAgent.split(';')[1];
    }
    if (isIOS) {
      OSVision = navigator.userAgent.split(';')[1].match(/(\d+)_(\d+)_?(\d+)?/)[0];
    }
    return OSVision;
  }

  render() {
    const { data, params } = this.state;
    if (!data) {
      return null;
    }
    return (
      <div>
        <Switch>
          <Route path="/antiFake">
            <AntiFake data={data} params={params} />
          </Route>

          <Route path="/errorcode">
            <ErrorCode data={data} />
          </Route>
        </Switch>
      </div>
    );
  }
}
