export default {
  homePage: {
    GET_DETAILS: '/fake/record/search/api/baseFakeSearchProcessJudge',
    GET_RECORDS: '/fake/record/search/api/getSingleSearchDetailsListByH5',
    // 请求位置接口
    SET_LOCATION: '/fake/record/search/api/location',
    // 刷新扫码信息
    SET_REFRESH: '/fake/record/search/api/refresh',
  },
};
