import getCustomerConfig from 'public/customerConfig/index.js';
//
const customerConfig = getCustomerConfig();
export const headerHeight = 60;
export const prefix = '/apiInterface';
export const downloadPrefix = '/apiInterface/trace/common/download';
export const amapkey = '12fea8c7f7f0ec1a0a851b89feaf84ea'; //高德地图密钥
export const device = (() => {
  const result = {};
  const ua = window.navigator.userAgent.toLocaleLowerCase();
  result.isIE = ua.indexOf('trident') >= 0; // 是否是IE浏览器
  result.isIE11 = ua.indexOf('trident') >= 0 && ua.indexOf("rv:11.0") >= 0;
  result.isChrome = ua.indexOf('chrome') >= 0; // 是否是谷歌浏览器
  result.isFireFox = ua.indexOf('firefox') >= 0; // 是否是火狐浏览器
  result.isSafari = result.isChrome ? false : ua.indexOf('safari') >= 0; // 是否是Safari浏览器
  result.isWeiXin = ua.indexOf('micromessenger') >= 0; // 是否是微信内置浏览器
  result.isMobile = ua.indexOf('mobile') >= 0; // 是否是移动端浏览器
  return result;
})();
export const themes = ['#3296fa', '#13c2c2', '#fa8c16'];
//

//
// 是否是本地开发环境
export const isDev =
  location.href.indexOf('http://localhost') === 0 ||
  location.href.indexOf('http://192.168') === 0 ||
  location.href.indexOf('http://127.0.0.1') === 0;
// 是否是远程开发环境
const isRemoteDev = location.href.indexOf('.kf315.net') >= 0 && location.href.indexOf('-dev.') >= 0;
// 是否是测试环境
export const isTesting = location.href.indexOf('.kf315.net') >= 0;
// 是否是生产环境
export const isProduction = !(isDev || isTesting);
// 本地开发设置
const devConfig = (() => {
  const host = location.origin.replace(`:${location.port}`, '');
  return {
    //
    domain: location.hostname,
    // 企业中心地址
    orgSite: `${host}:7071`,
    // 用户中心地址
    userCenterSite: `${host}:7070`,
    // 用户中心登录页
    userCenterLogin: `${host}:7070/#/user/login`,
    // 系统中心地址
    systemSite: `${host}:7073`,
    // 系统中心  企业着落页地址
    systemLandingPageSite: `${host}:7073/#/platform/landingPage/exhibit`,
    // 平台中心地址
    platformSite: `${host}:7072`,
    // 新用户注册
    register: `${host}:7070/#/user/register`,
    // 单点登录iframe的src
    ssoSrc: `${host}:7070/#/frame/user/login`,
    // 图片上传返回值
    fileDomain: 'http://filetest.cjm.so',
    // 溯源预览，h5模板预览
    previewWarp: `${host}:7077`,
    mapjs: `http://dev-gis.kf315.net`,
    nurturePreview: `${host}:7100`,
    //贫困户预览
    previewPorverty: `${host}:3005`,
    // 溯源扫码
    traceH5: `${host}:7077/#/traceH5`,
    // 码营销H5
    integralMarketingH5Site: `${host}:7081`,
    //会员御花园
    integralShop: `${host}:7086`,
    //政府对接
    govConnectSite: `${host}:7088`,
    //iot平台
    iotSite: `${host}:7090`,
    //一体机
    aioSite: `${host}:3050`,
    // 保险理赔H5
    insuranceDomain: `${host}:3004/#/trace`,
    //数据屏地址
    monitorScreenConfigPage: `${host}:8081`,
    //润达数据屏预览地址
    monitorScreenConfigPageRunDa: 'http://freedom.kf315.net/datascreen/runda/',
    monitorScreenConfigPageGugeng: 'http://localhost:8085/#/',
    //微页配置工具地址
    microPageSite: 'http://localhost:8586',
    //H5工具地址
    h5ToolsSite: {
      home: 'http://localhost:9080/h5Tools/home/index.html', //访问地址
      preview: 'http://localhost:9081/h5Tools/preview/index.html', //预览地址
      settings: 'http://localhost:9082/h5Tools/settings/index.html' //配置地址
    },
    //防伪模板三链接
    antiHref3: 'http://antibackend.h5.kf315.net/u/r/h3',
    // 农安信用跳转链接
    creditHref: 'http://192.168.5.52:8001/h5/credit.html',
    // 企业工具跳转链接
    mobileAppHref: 'https://test-mobile-website-h5.jgwcjm.com',
    //数字乡村一户一码
    digitalVillageHref: `${host}:3006`,
    //会员H5
    shopH5: `${host}:7086`
  };
})();


// 远程开发环境设置
const remoteDevConfig = {
  //
  domain: '.kf315.net',
  // 企业中心地址
  orgSite: 'http://enterprise-dev.center.kf315.net',
  // 用户中心地址
  userCenterSite: 'http://user-dev.center.kf315.net',
  // 用户中心登录页
  userCenterLogin: 'http://user-dev.center.kf315.net/#/user/login',
  // 系统中心地址
  systemSite: 'http://system-dev.center.kf315.net',
  // 系统中心  企业着落页地址
  systemLandingPageSite: `http://system-dev.center.kf315.net/#/platform/landingPage/exhibit`,
  // 平台中心地址
  platformSite: 'http://platform-dev.center.kf315.net',
  // 新用户注册
  register: 'http://user-dev.center.kf315.net/#/user/register',
  // 单点登录iframe的src
  ssoSrc: 'http://user-dev.center.kf315.net/#/frame/user/login',
  // 图片上传返回值
  fileDomain: 'http://filetest.cjm.so',
  // 溯源预览，h5模板预览
  previewWarp: 'http://trace-dev.h5.kf315.net',
  mapjs: 'http://gis.kf315.net',
  nurturePreview: 'http://nurtureh5.kf315.net',
  //贫困户预览
  previewPorverty: 'http://poverty.kf315.net',
  // 溯源扫码
  traceH5: 'http://trace-dev.h5.kf315.net/#/traceH5',
  // 码营销H5
  integralMarketingH5Site: 'http://market-dev.h5.kf315.net',
  //会员御花园
  integralShop: 'http://shop-dev.h5.kf315.net',
  //政府对接
  govConnectSite: 'http://gov.connect-dev.kf315.net',
  //iot平台
  iotSite: 'http://iot-dev.kf315.net',
  //一体机
  aioSite: 'http://aio.h5.kf315.net',
  // 保险理赔H5
  insuranceDomain: 'http://insurance.h5.kf315.net/#/trace',
  //数据屏地址
  monitorScreenConfigPage: 'http://localhost:8081',
  //润达数据屏预览地址
  monitorScreenConfigPageRunDa: 'http://freedom.h5.kf315.net/datascreen/runda/',
  monitorScreenConfigPageGugeng: 'http://freedom.h5.kf315.net/datascreen/gugeng/',
  //微页配置工具地址
  microPageSite: 'http://designw.kf315.net',
  //H5工具地址
  h5ToolsSite: {
    home: 'https://h5toolsv3.kf315.net/h5Tools/home/index.html', //访问地址
    preview: 'https://h5toolsv3.kf315.net/h5Tools/preview/index.html', //预览地址
    settings: 'https://h5toolsv3.kf315.net/h5Tools/settings/index.html' //配置地址
  },
  //防伪模板三链接
  antiHref3: 'http://antibackend.h5.kf315.net/u/r/h3',
  // 农安信用跳转链接
  creditHref: 'http://192.168.5.52:8001/h5/credit.html',
  // 企业工具跳转链接
  mobileAppHref: 'https://test-mobile-website-h5.jgwcjm.com',
  //数字乡村一户一码
  digitalVillageHref: 'http://digitalVillage.kf315.net',
};
// 测试环境设置
const testConfig = {
  //
  domain: '.kf315.net',
  // 企业中心地址
  orgSite: 'http://enterprise.center.kf315.net',
  // 用户中心地址
  userCenterSite: 'http://user.center.kf315.net',
  // 用户中心登录页
  userCenterLogin: 'http://user.center.kf315.net/#/user/login',
  // 系统中心地址
  systemSite: 'http://system.center.kf315.net',
  // 系统中心  企业着落页地址
  systemLandingPageSite: `http://system.center.kf315.net/#/platform/landingPage/exhibit`,
  // 平台中心地址
  platformSite: 'http://platform.center.kf315.net',
  // 新用户注册
  register: 'http://user.center.kf315.net/#/user/register',
  // 单点登录iframe的src
  ssoSrc: 'http://user.center.kf315.net/#/frame/user/login',
  // 图片上传返回值
  fileDomain: 'http://filetest.cjm.so',
  // 溯源预览，h5模板预览
  previewWarp: 'http://trace.h5.kf315.net',
  mapjs: 'http://gis.kf315.net',
  nurturePreview: 'http://nurtureh5.kf315.net',
  //贫困户预览
  previewPorverty: 'http://poverty.kf315.net',
  // 溯源扫码
  traceH5: 'http://trace.h5.kf315.net/#/traceH5',
  // 码营销H5
  integralMarketingH5Site: 'https://market.h5.kf315.net',
  //会员御花园
  integralShop: 'https://shop.h5.kf315.net',
  //政府对接
  govConnectSite: 'http://gov.connect.kf315.net',
  //iot平台
  iotSite: 'http://iot.kf315.net',
  //一体机
  aioSite: 'http://aio.h5.kf315.net',
  // 保险理赔H5
  insuranceDomain: 'http://insurance.h5.kf315.net/#/trace',
  //数据屏地址
  monitorScreenConfigPage: 'http://datav3.kf315.net',
  //润达数据屏预览地址
  monitorScreenConfigPageRunDa: 'http://freedom.h5.kf315.net/datascreen/runda/',
  monitorScreenConfigPageGugeng: 'http://freedom.h5.kf315.net/datascreen/gugeng/',
  //微页配置工具地址
  microPageSite: 'http://designw.kf315.net',
  //H5工具地址
  h5ToolsSite: {
    home: 'https://h5toolsv3.kf315.net/h5Tools/home/index.html', //访问地址
    preview: 'https://h5toolsv3.kf315.net/h5Tools/preview/index.html', //预览地址
    settings: 'https://h5toolsv3.kf315.net/h5Tools/settings/index.html' //配置地址
  },
  //防伪模板三链接
  antiHref3: 'http://antibackend.h5.kf315.net/u/r/h3',
  // 农安信用跳转链接
  creditHref: 'http://192.168.5.52:8001/h5/credit.html',
  // 企业工具跳转链接
  mobileAppHref: 'https://test-mobile-website-h5.jgwcjm.com',
  //数字乡村一户一码
  digitalVillageHref: 'http://digitalVillage.kf315.net',
  //是否为超级码的码连接
  isCjmCode:'//code.kf315.net'
};
// 生产环境设置
const productionConfig = {
  //
  domain: '.jgwcjm.com',
  // 企业中心地址
  orgSite: 'http://org.jgwcjm.com',
  // 用户中心地址
  userCenterSite: 'http://login.jgwcjm.com',
  // 用户中心登录页
  userCenterLogin: 'http://login.jgwcjm.com/#/user/login',
  // 系统中心地址
  systemSite: 'http://system.center.jgwcjm.com',
  // 系统中心  企业着落页地址
  systemLandingPageSite: `http://system.center.jgwcjm.com/#/platform/landingPage/exhibit`,
  // 平台中心地址
  platformSite: 'http://platform.jgwcjm.com',
  // 新用户注册
  register: 'http://login.jgwcjm.com/#/user/register',
  // 单点登录iframe的src
  ssoSrc: 'http://login.jgwcjm.com/#/frame/user/login',
  // 图片上传返回值
  fileDomain: 'http://file.cjm.so',
  // 溯源预览，h5模板预览
  previewWarp: 'https://traceh5.jgwcjm.com',
  mapjs: 'https://gis.jgwcjm.com',
  nurturePreview: 'https://nurtureh5.jgwcjm.com',
  //贫困户预览
  previewPorverty: 'https://poverty.jgwcjm.com',
  // 溯源扫码
  traceH5: 'https://traceh5.jgwcjm.com/#/traceH5',
  // 码营销H5
  // integralMarketingH5Site: 'http://market.h5.jgwcjm.com',
  integralMarketingH5Site: 'https://marketh5.jgwcjm.com',
  //会员御花园
  // integralShop: 'http://shop.h5.jgwcjm.com',
  integralShop: 'https://shoph5.jgwcjm.com',
  //政府对接
  govConnectSite: 'http://gov.connect.jgwcjm.com',
  //iot平台
  iotSite: 'http://iot.jgwcjm.com',
  //一体机
  aioSite: 'http://aio.h5.jgwcjm.com',
  // 保险理赔H5
  insuranceDomain: 'http://insurance.h5.jgwcjm.com/#/trace',
  //数据屏地址
  monitorScreenConfigPage: 'http://datav.jgwcjm.com',
  //润达数据屏预览地址
  monitorScreenConfigPageRunDa: 'https://freedom.jgwcjm.com/dataScreen/runda/',
  monitorScreenConfigPageGugeng: 'https://freedom.jgwcjm.com/dataScreen/gugeng/',
  //微页配置工具地址
  microPageSite: 'http://designw.jgwcjm.com',
  //H5工具地址
  h5ToolsSite: {
    home: 'https://h5tools.jgwcjm.com/h5Tools/home/index.html', //访问地址
    preview: 'https://h5tools.jgwcjm.com/h5Tools/preview/index.html', //预览地址
    settings: 'https://h5tools.jgwcjm.com/h5Tools/settings/index.html' //配置地址
  },
  //防伪模板三链接
  antiHref3: 'http://antibackend.jgwcjm.com/u/r/h3',
  // 农安信用跳转链接
  creditHref: 'http://naxy.net:8001/h5/credit.html',
  // 企业工具跳转链接
  mobileAppHref: 'https://mobile-website-h5.jgwcjm.com',
  //数字乡村一户一码
  digitalVillageHref: 'https://xc.jgwcjm.com',
  //是否为超级码的码连接
  isCjmCode:'//51cjm.cn'
};
//
let config = {};
if (isDev) {
  config = devConfig;
} else if (isRemoteDev) {
  config = remoteDevConfig;
} else if (isTesting) {
  config = testConfig;
} else if (isProduction) {
  config = productionConfig;
}
const finalConfig = { ...config, ...customerConfig };
export default finalConfig;
