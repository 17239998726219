import React from 'react';
import { connect } from 'dva';
import { ajaxSync } from 'src/framework/utils/ajax';
import { Flex, WingBlank, Toast, Modal, ActivityIndicator } from 'antd-mobile';
import api from 'src/sites/antiFakeH5_2/config/api';
import { routerRedux, Switch, Route } from 'dva/router';
import FocusFigure from './components/Carousel';
import BrandInfor from './components/brandInformation';
import AntiFakeWap from './components/antiFakeWarp';
import ModalWarp from './components/Model';
import errorCode from './errorCode';

//
@connect()
export default class extends React.Component {
  state = {
    params: '',
    animating: false,
    cont: '3',
    customMessage: [],
    modal1: false,
    results: {},
    productDetail: {},
    isDown: 0,
    down: {
      display: 'block',
    },
    record: null,
    modal2: false,
    tip: null,
    checkrecord: [],
    productView: false,
  };

  componentWillMount() { }

  componentDidMount() {
    const results = this.props.data;
    let customMessage = [];
    const productDetail = JSON.parse(results.productDetail);
    if (productDetail.customMessage && productDetail.customMessage !== 'null') {
      customMessage = JSON.parse(productDetail.customMessage);
    }
    this.setState({
      results,
      cont: results.resultCode,
      animating: false,
      customMessage,
      productDetail,
    });
    if (!results.isRefresh) {
      if (results.resultCode === '1') {
        this.setState({ modal1: true });
      } else if (results.resultCode === '2') {
        this.setState({ modal1: false, modal2: false });
      } else if (results.resultCode === '3') {
        this.interval = setTimeout(() => this.loaddemo(), 2000);
        this.setState({ modal2: true, modal1: false });
      } else {
        this.interval = setTimeout(() => this.loaddemo(), 2000);
        this.setState({ modal2: true, modal1: false });
      }
    }
  }

  _getDataTest(latitude, longitude) {
    const params = this.props.params;
    params.jingdu = longitude || '';
    params.weidu = latitude || '';
    (params.isLoation = 'yes'), this.setState({ animating: true });
    ajaxSync
      .post(api.homePage.GET_DETAILS, params)
      .then(res => {
        const data = res.res;
        if (data.state === 200) {
          if (
            data.results.tips === '您所查询的标签暂未生效' ||
            data.results.tips === '您所查询的标签有误' ||
            data.results.tips === '服务器异常' || 
            data.results.tips === '该码不存在，请仔细核对！'
          ) {
            const _data = data.results;
            this.props.dispatch(routerRedux.push({ pathname: '/errorcode', payload: { _data } }));
          } else {
            let customMessage = [];
            const results = JSON.parse(data.results.productDetail);
            if (productDetail.customMessage && productDetail.customMessage !== 'null') {
              customMessage = JSON.parse(productDetail.customMessage);
            }
            this.setState({
              results: data.results,
              productDetail,
              cont: data.results.resultCode,
              modal1: true,
              animating: false,
              customMessage,
            });
          }
        } else {
          Toast.info(data.msg);
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  // 两秒钟自动获取位置;

  loaddemo() {
    const _this = this;
    _this.baiduPosition();
    this.setState({ modal2: false });
  }
  baiduPosition() {
    const _this = this;
    var geolocation = new qq.maps.Geolocation('KATBZ-OYE6X-U7D4H-ZQXVA-G7DBO-CQBMF', 'myapp');
    function showPosition(position) {
      _this.getCurrentlocation(position.lat, position.lng);
    }

    function showErr() {
      console.log('定位失败');
      _this.getCurrentlocation();
    }
    geolocation.getLocation(showPosition, showErr);
  }

  getCurrentlocation(latitude, longitude) {
    const results = this.props.data;
    const params = this.props.params;
    if (results.resultCode == 3) {
      const locationParams = {
        codeTypeId: params.codeTypeId,
        fakeCode: params.fakeCode,
        globalFakeSearchId: results.globalFakeSearchId,
        jingDu: longitude,
        weiDu: latitude,
      };
      ajaxSync.post(api.homePage.SET_LOCATION, locationParams).then(resLocation => {
        const cont = resLocation.res.results.resultCode;
        const tip = resLocation.res.results.tip;
        if (cont == 5) {
          this.setState({ modal1: true, cont, tip });
        } else {
          this.setState({ modal1: true, cont, tip });
        }
      });
    }
  }

  // 关掉modal
  closeButton() {
    this.setState({
      modal1: false,
    });
  }

  _closeButton() {
    this.setState({
      modal2: false,
    });
  }

  // 商品详细信息展示or隐藏
  productDet() {
    const { isDown } = this.state;
    if (isDown === 0) {
      this.setState({
        down: {
          display: 'none',
        },
        isDown: 1,
      });
    } else {
      this.setState({
        down: {
          display: 'block',
        },
        isDown: 0,
      });
    }
  }

  // 查看记录
  checkRecord() {
    const params = this.props.params;
    const _fakeCode = params.fakeCode;
    const _codeTypeId = params.codeTypeId;
    ajaxSync
      .get(`${api.homePage.GET_RECORDS}?fakeCode=${_fakeCode}&` + `codeTypeId=${_codeTypeId}`)
      .then(res => {
        const data = res.res.results;
        if (res.res.state === 200) {
          this.setState({
            checkrecord: data,
            modal1: true,
            cont: 'more',
          });
        } else {
          Toast.info(data.msg);
        }
      })
      .catch(err => {
        console.error(err);
      });
  }

  unNormal() {
    this.setState({
      cont: '407',
    });
  }

  checkProductDetail = () => {
    this.setState({ productView: true })
  }

  closeProductView = () => {
    this.setState({ productView: false })
  }

  render() {
    const { results, customMessage, isDown, productDetail, productView } = this.state;
    const params = this.props.params;
    const _fakeCode = params.fakeCode;
    return (
      <div className="content">
        {results.flickCount > 4 ? (
          <div className="header five">
            <span>
              当前为第
              {results.flickCount}
              次扫码
            </span>
            <span className="title-more" onClick={this.checkRecord.bind(this)}>
              点击查看记录>>
            </span>
          </div>
        ) : results.flickCount > 2 ? (
          <div className="header three">
            <span>
              当前为第
              {results.flickCount}
              次扫码
            </span>
            <span className="title-three" onClick={this.checkRecord.bind(this)}>
              点击查看记录>>
            </span>
          </div>
        ) : results.flickCount === 2 ? (
          <div className="header one">
            <span>
              当前为第
              {results.flickCount}
              次扫码
            </span>
            <span className="title-more" onClick={this.checkRecord.bind(this)}>
              点击查看记录>>
            </span>
          </div>
        ) : (
                <div className="header one">首次扫码 值得信赖</div>
              )}
        {productDetail.productImgUrl ? (
          <FocusFigure data={productDetail.productImgUrl} results={results} />
        ) : null}
        <WingBlank size="lg">
          <div className="productDetails">
            <div className="product-warp">
              <Flex>
                <Flex.Item>
                  <div className="left-first"> 商品详细信息 </div>
                </Flex.Item>
                <Flex.Item>
                  <div className="right-down-warp">
                    {isDown === 0 ? (
                      <span className="right-down" onClick={this.productDet.bind(this)} />
                    ) : (
                        <span className="right-up" onClick={this.productDet.bind(this)} />
                      )}
                  </div>
                </Flex.Item>
              </Flex>
              <div style={this.state.down}>
                {productDetail.businessName ?
                  <Flex>
                    <Flex>
                      <div className="left"> 企业名称：</div>
                    </Flex>
                    <Flex.Item>
                      <div className="right"> {productDetail.businessName}</div>
                    </Flex.Item>
                  </Flex> : null
                }
                {productDetail.brand ?
                  <Flex>
                    <Flex>
                      <div className="left"> 品  牌： </div>
                    </Flex>
                    <Flex.Item>
                      <div className="right"> {productDetail.brand}</div>
                    </Flex.Item>
                  </Flex> : null
                }
                {productDetail.productName ? (
                  <Flex>
                    <Flex>
                      <div className="left"> 产品名称： </div>
                    </Flex>
                    <Flex.Item>
                      <div className="right"> {productDetail.productName}</div>
                    </Flex.Item>
                  </Flex>) : null
                }
                {productDetail.productBatchName ?
                  <Flex>
                    <Flex>
                      <div className="left"> 产品批次：</div>
                    </Flex>
                    <Flex.Item>
                      <div className="right"> {productDetail.productBatchName}</div>
                    </Flex.Item>
                  </Flex> : null
                }
                {productDetail.productModel ?
                  <Flex>
                    <Flex>
                      <div className="left"> 产品型号：</div>
                    </Flex>
                    <Flex.Item>
                      <div className="right"> {productDetail.productModel}</div>
                    </Flex.Item>
                  </Flex> : null
                }
                {productDetail.productSpecificationsName ?
                  <Flex>
                    <Flex>
                      <div className="left"> 产品规格：</div>
                    </Flex>
                    <Flex.Item>
                      <div className="right"> {productDetail.productSpecificationsName}</div>
                    </Flex.Item>
                  </Flex> : null
                }
                {productDetail.smallUnitName ?
                  <Flex>
                    <Flex>
                      <div className="left"> 最小单位：</div>
                    </Flex>
                    <Flex.Item>
                      <div className="right"> {productDetail.smallUnitName}</div>
                    </Flex.Item>
                  </Flex> : null
                }
                {productDetail.productPrice ?
                  <Flex>
                    <Flex>
                      <div className="left"> 价  格：</div>
                    </Flex>
                    <Flex.Item>
                      <div className="right"> {productDetail.productPrice}元</div>
                    </Flex.Item>
                  </Flex> : null
                }
                {productDetail.productIntroduce ?
                  <Flex>
                    <Flex>
                      <div className="left"> 产品介绍：</div>
                    </Flex>
                    <Flex.Item>
                      <div className="rightView" onClick={this.checkProductDetail}> 查看</div>
                    </Flex.Item>
                  </Flex> : null
                }
                {customMessage.length > 0
                  ? customMessage.map((item, index) => {
                    return (
                      <Flex key={index}>
                        <Flex.Item>
                          <div className="left"> {item.title}: </div>
                        </Flex.Item>
                        <Flex.Item>
                          <div className="right"> {item.content}</div>
                        </Flex.Item>
                      </Flex>
                    );
                  })
                  : null}
              </div>
            </div>
          </div>
          <BrandInfor data={results} fakeCode={_fakeCode} />
          <AntiFakeWap data={results} />
          <footer>
            <a href="http://www.app315.net/" target="_blank">
              技术支持： 甲骨文超级码科技股份 <br />
              Copyright 2005 - 2020 All Reserved
            </a>
          </footer>
        </WingBlank>
        <ModalWarp
          cont={this.state.cont}
          modal={this.state.modal1}
          closeButton={this.closeButton.bind(this)}
          voiceContent={this.state.results}
          tip={this.state.tip}
          voiceUrlAdress={results.voiceUrlAdress}
          data={productDetail}
          unNormal={this.unNormal.bind(this)}
          checkrecord={this.state.checkrecord}
        />
        <Modal
          visible={this.state.modal2}
          transparent
          maskClosable={false}
          closable={false}
          title={
            <div className="modal-header">
              温馨提示
              <span onClick={this.closeButton.bind(this)}>×</span>
            </div>
          }
          footer={[]}
          className="home-modal-warp"
        >
          <div className="modal-content-position">
            <div className="modal-result-position">
              当前扫码次数过多，为了精确防伪，请授权位置定位
            </div>
            <div className="modal-loading">
              {' '}
              <span> 加载中 </span>
            </div>
          </div>
        </Modal>

        <Modal
          visible={productView}
          transparent
          maskClosable={false}
          title={
            <div className="modal-header">
              产品详情
              <span onClick={this.closeProductView}>×</span>
            </div>
          }
          footer={[]}
          className="home-modal-warp"
          style={{ height: '400px' }}
        >
          <div dangerouslySetInnerHTML={{ __html: productDetail.productIntroduce }} />
        </Modal>


        <ActivityIndicator toast text="Loading..." animating={this.state.animating} />
        <Switch>
          <Route path="/errorcode" component={errorCode} />
        </Switch>
      </div>
    );
  }
}
